import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import bsCustomFileInput from 'bs-custom-file-input'
export default class extends Controller {
  static targets = ['response', 'form']

  connect() {
    bsCustomFileInput.init()
  }

  submit(e) {
    Rails.fire(e.target.form, 'submit')
  }

  renderErrors(e) {
    const xhr = e.detail[2]
    e.currentTarget.outerHTML = xhr.response
    // let event = document.createEvent('Event')
    // document.dispatchEvent(event)
  }

  renderSuccess(e) {
    const el = e.currentTarget
    const data = e.detail[0]
    if (!data.body) return null
    if (el.dataset.placement) {
      const df = this._documentFragment(e)
      if (el.dataset.placement === 'append') this._append(el, df)
      else if (el.dataset.placement === 'prepend') this._prepend(el, df)
      else if (el.dataset.placement === 'replace') this._replace(el, df)
      else if (el.dataset.placement === 'replace_inner')
        this._replaceInner(el, e)
    }
    if (el.dataset.reset === 'true') el.reset()
  }

  toggleField(e) {
    const el = e.currentTarget
    const fields = el.dataset.field.split(',')
    for (const f of fields) {
      const field = document.querySelector(f)
      const container = field.parentElement.parentElement
      if (el.checked) container.classList.remove('hide')
      else container.classList.add('hide')
    }
  }

  // Private methods

  _append(element, fragment) {
    this._target(element).append(fragment)
  }

  _prepend(element, fragment) {
    this._target(element).prepend(fragment)
  }

  _replace(element, fragment) {
    this._target(element).replaceWith(fragment)
  }

  _replaceInner(el, e) {
    const element = this._target(el)
    const xhr = e.detail[2]
    element.innerHTML = xhr.responseText
  }

  _documentFragment(e) {
    const data = e.detail[0]
    const df = document.createDocumentFragment()
    for (const node of data.body.children) df.appendChild(node)
    return df
  }

  _target(el) {
    return document.querySelector(el.dataset.responseTarget)
  }
}
