import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['loader', 'wrapper']

  connect() {
    const key = this.wrapperTarget.dataset.stripeKey
    window.stripe = window.Stripe(key)
    window.clientSecret = this.wrapperTarget.dataset.secret
    window.redirectUrl = this.wrapperTarget.dataset.url
    var elements = window.stripe.elements()
    var style = { base: { fontSize: '16px', color: '#495057' } }
    window.card = elements.create('card', {
      hidePostalCode: true,
      style: style,
    })
    window.card.mount('#card-element')

    window.card.addEventListener('change', function (event) {
      var displayError = document.getElementById('card-errors')
      if (event.error) {
        displayError.textContent = event.error.message
        displayError.hidden = false
      } else {
        displayError.textContent = ''
        displayError.hidden = true
      }
    })
  }

  makePayment(ev) {
    const form = ev.target.form
    var name = form.querySelector('#name')
    var email = form.querySelector('#email')
    var address1 = form.querySelector('#address_line1')
    var city = form.querySelector('#address_city')
    var zip = form.querySelector('#address_zip')
    var additionalData = {
      name: name ? name.value : undefined,
      email: email ? email.value : undefined,
      address: {
        line1: address1 ? address1.value : undefined,
        city: city ? city.value : undefined,
        postal_code: zip ? zip.value : undefined,
        country: 'GB',
      },
    }
    var oldTextContent = ev.currentTarget.textContent
    var currentTarget = ev.currentTarget
    var displayError = document.getElementById('card-errors')
    if (form.checkValidity()) {
      ev.preventDefault()
      currentTarget.disabled = true
      currentTarget.textContent = 'Please wait...'
      window.stripe
        .confirmCardPayment(window.clientSecret, {
          payment_method: {
            card: window.card,
            billing_details: additionalData,
          },
        })
        .then(function (result) {
          if (result.error) {
            // Show error to your customer (e.g., insufficient funds)
            const paymentIntent = result.error.payment_intent
            if (paymentIntent) {
              displayError.textContent = result.error.message
              displayError.hidden = false
              window.location.href = `${window.redirectUrl}&result=failed&Message=${result.error.message}&intent_id=${paymentIntent.id}&amount=${paymentIntent.amount}`
            } else {
              currentTarget.disabled = false
              currentTarget.textContent = oldTextContent
            }
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'requires_capture') {
              const paymentIntent = result.paymentIntent
              window.location.href = `${window.redirectUrl}&result=requires_capture&intent_id=${paymentIntent.id}&amount=${paymentIntent.amount}`
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
              window.card = null
              window.stripe = null
              window.clientSecret = null
              window.redirectUrl = null
              displayError.hidden = true
            }
          }
        })
    }
  }
}
