import { Controller } from 'stimulus'
import { Modal } from 'bootstrap.native/dist/bootstrap-native-v4'

export default class extends Controller {
  static targets = ['trigger']

  render(e) {
    const [data] = e.detail
    // const parser = new DOMParser()
    // const node = parser.parseFromString(data, 'text/html')
    const modal = data.querySelector('.modal')
    document.body.appendChild(modal)
    const instance = new Modal(modal, { backdrop: 'static', keyboard: false })
    modal.addEventListener('hidden.bs.modal', (e) => {
      e.target.remove()
    })
    instance.show()
  }
}
