import { Controller } from 'stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  connect() {
    const el = this.element
    let options = {}
    if (el.dataset.autonumeric) options = JSON.parse(el.dataset.autonumeric)
    return new AutoNumeric(el, options)
  }
}
