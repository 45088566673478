import { Controller } from 'stimulus'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['loader', 'card']

  toggelForms(e) {
    e.preventDefault()
    const pzFormWrapperEl = document.querySelector(`[data-target="pzForm"]`)
    const pvFormWrapperEl = document.querySelector(
      `[data-pvform="formContainer"]`
    )
    pzFormWrapperEl.hidden = !pzFormWrapperEl.hidden
    pvFormWrapperEl.hidden = !pvFormWrapperEl.hidden
    if (!pvFormWrapperEl.hidden) {
      const deleteButton = document.querySelector(`[data-target="cancelAbort"]`)
      deleteButton.hidden = true
    }
  }

  makePayment(e) {
    if (this.cardTarget.value) {
      const pzFormWrapperEl = document.querySelector(`[data-target="pzForm"]`)
      const cancelAbortEl = document.querySelector(
        `[data-target="cancelAbort"]`
      )
      pzFormWrapperEl.hidden = true
      cancelAbortEl.hidden = true
      this.loaderTarget.hidden = false

      const updateMessageIn = parseInt(this.loaderTarget.dataset.updatemessage)
      const redirectTimeoutIn = parseInt(
        this.loaderTarget.dataset.redirecttimeout
      )
      window.updateMessageTimeoutId = setTimeout(
        this.updateSpinnerMessage,
        updateMessageIn,
        this.loaderTarget
      )
      window.redirectAfterTimeoutId = setTimeout(
        this.redirectAfterTimeout,
        redirectTimeoutIn,
        this.loaderTarget
      )
    } else {
      e.preventDefault()
      alert('Please select a card to continue')
    }
  }

  submit(e) {
    const gateway = e.currentTarget.dataset.gateway
    if (gateway === 'pay_vector') {
      e.currentTarget.form.submit()
    } else {
      Rails.fire(e.currentTarget.form, 'submit')
    }
  }

  saveCardSucess(e) {
    const data = e.detail[0]
    const saveCardEl = e.target.querySelector('[data-target="cardtoggle"]')
    if (data.success) {
      if (data.save_card) {
        alert('Acknowledged! Card details will be saved for future topups')
      } else {
        alert('Acknowledged! Card details will NOT be saved for future topups')
      }
    } else {
      alert('Something went wrong! Please try again')
      saveCardEl.checked = false
    }
  }

  saveCardError(e) {
    alert('Error Occured! Please try again')
    const saveCardEl = e.target.querySelector('[data-target="cardtoggle"]')
    saveCardEl.checked = false
  }

  updateSpinnerMessage(loaderEl) {
    clearTimeout(window.updateMessageTimeoutId)
    const messageEl = loaderEl.querySelector('.wait-message')
    messageEl.innerHTML =
      'We apologise for the delay, the server is currently too busy, We will redirect you for further instructions shortly.'
  }

  redirectAfterTimeout(loaderEl) {
    clearTimeout(window.redirectAfterTimeoutId)
    window.location.href = loaderEl.dataset.timeoutredirecturl
  }

  // clearTimeouts(e) {
  //   if (window.updateMessageTimeoutId) {
  //     clearTimeout(window.updateMessageTimeoutId)
  //   }
  //   if (window.redirectAfterTimeoutId) {
  //     clearTimeout(window.redirectAfterTimeoutId)
  //   }
  // }
}
