import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['iframeLoader', 'loader', 'container']

  connect() {
    if (this.hasIframeLoaderTarget && this.hasLoaderTarget) {
      this.iframeLoaderTarget.click()
    }
  }

  renderIframe(e) {
    const data = e.detail[0]
    // const ele = document.createElement('iframe')
    // ele.src = data['url']
    // this.containerTarget.appendChild(ele)
    let iframEL = data.querySelector('iframe')
    if (!iframEL) iframEL = data.querySelector('body')
    this.containerTarget.appendChild(iframEL)
    this.containerTarget.hidden = false
    this.loaderTarget.hidden = true
  }
}
