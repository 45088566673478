import { Controller } from 'stimulus'
import { Modal } from 'bootstrap.native/dist/bootstrap-native-v4'

export default class extends Controller {
  preview(e) {
    e.preventDefault()
    const currentTarget = e.currentTarget
    const fileSrc = currentTarget.href
    let contentEle

    if (currentTarget.dataset.isImage === 'true') {
      contentEle = this.imageElement(fileSrc)
    } else {
      contentEle = this.iframeElement(fileSrc)
    }

    this.showModal(contentEle)
  }

  showModal(contentEle) {
    const modal = document.querySelector('#preview-modal')
    const modalBody = modal.querySelector('.modal-body')
    modalBody.innerHTML = ''
    modalBody.appendChild(contentEle)
    document.body.appendChild(modal)
    const instance = new Modal(modal)
    instance.show()
  }

  imageElement(fileSrc) {
    const ele = document.createElement('img')
    ele.src = fileSrc
    ele.id = 'attachmentContent'
    ele.className = 'mw-100'
    return ele
  }

  iframeElement(fileSrc) {
    const ele = document.createElement('iframe')
    ele.src = `https://docs.google.com/viewer?embedded=true&url=${fileSrc}`
    ele.width = '100%'
    ele.id = 'attachmentContent'
    ele.height = window.innerHeight * 0.8
    return ele
  }
}
