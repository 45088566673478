import { Controller } from 'stimulus'

export default class extends Controller {
  togglePaymentGateway(e) {
    e.preventDefault()
    const formEl = e.currentTarget.form
    const paymentGateWays = formEl.querySelectorAll('.payment-gateway')
    paymentGateWays.forEach(function (pg) {
      pg.hidden = true
    })
    const toSHowEl = formEl.querySelector(
      `[data-target="${e.currentTarget.value}"`
    )
    toSHowEl.hidden = false
  }
}
