import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['loader']

  connect() {
    if (this.hasLoaderTarget) {
      const interval = 1000
      window.redirectTimeoutIn = parseInt(
        this.loaderTarget.dataset.redirecttimeout
      )
      const loaderEl = this.loaderTarget
      window.topupStatusInterval = setInterval(
        this.startReloadCountDown,
        interval,
        loaderEl,
        interval
      )
    }
  }

  startReloadCountDown(loaderEl, interval) {
    window.redirectTimeoutIn = window.redirectTimeoutIn - interval
    const countdown = window.redirectTimeoutIn / 1000
    if (countdown > 0) {
      const cdownEl = loaderEl.querySelector('span#countdown')
      cdownEl.innerHTML = countdown
    } else {
      clearInterval(window.topupStatusInterval)
      location.reload()
      // window.location.href = loaderEl.dataset.timeoutredirecturl
    }
  }
}
