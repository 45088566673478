import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']
  toggle(e) {
    e.preventDefault()
    this.containerTarget.classList.toggle(`sidenav-${this.direction(e)}-open`)
  }

  toggleCollapse(e) {
    e.preventDefault()
    this.containerTarget.classList.toggle(
      `sidenav-${this.direction(e)}-collapsed`
    )
  }

  direction(e) {
    return e.currentTarget.dataset.direction
  }
}
